





















import { useSiteHeader } from '@/composition/siteHeader'
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import CompareListingsWidget from '@/components/CompareListingsWidget.vue'
import { gsap } from 'gsap'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'LayoutDefault',

  components: {
    SiteHeader,
    SiteFooter,
    CompareListingsWidget,
  },

  setup() {
    const { items: navItems } = useSiteHeader({
      items: [
        {
          id: 'ourTeam',
          i18nPath: 'routes.ourTeam',
          toRoute: {
            name: 'OurTeam',
          },
        },
        {
          id: 'forSale',
          i18nPath: 'routes.forSale',
          toRoute: {
            name: 'ForSale',
          },
        },
        {
          id: 'rentals',
          i18nPath: 'routes.rentals',
          toRoute: {
            name: 'Rentals',
          },
        },
        {
          id: 'mlsSearch',
          i18nPath: 'routes.mlsSearch',
          toRoute: {
            name: 'MlsSearch',
          },
        },
        {
          id: 'experiences',
          i18nPath: 'routes.experiences',
          toRoute: {
            name: 'Experiences',
          },
        },
      ],
    })

    function viewBeforeEnter(el: Element) {
      gsap.set(el, {
        opacity: 0,
      })
    }

    function viewEnter(el: Element, done: () => void) {
      gsap.to(el, {
        duration: 0.15,
        opacity: 1,
        ease: 'linear',
        onComplete: done,
      })
    }

    function viewLeave(el: Element, done: () => void) {
      gsap.to(el, {
        duration: 0.15,
        opacity: 0,
        ease: 'linear',
        onComplete: done,
      })
    }

    return {
      navItems,
      viewBeforeEnter,
      viewEnter,
      viewLeave,
    }
  },
})
